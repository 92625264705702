import React, { Component } from "react"
import { navigate } from "gatsby"
import { AwesomeButton } from "react-awesome-button"

import "react-awesome-button/dist/themes/theme-rickiest.css"

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
      step: 1,
      redBorder: false,
    }
    this.nextStep = this.nextStep.bind(this)
    this.changeInput = this.changeInput.bind(this)
    this.ContactForm = React.createRef()
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  changeInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  nextStep() {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (this.state.step === 1) {
      if (this.state.name === "") {
        this.setState({
          redBorder: true,
        })
        setTimeout(() => {
          this.setState({
            redBorder: false,
          })
        }, 1000)
      } else {
        this.setState({
          step: this.state.step + 1,
        })
      }
    } else if (this.state.step === 2) {
      if (this.state.email === "" || !re.test(this.state.email)) {
        this.setState({
          redBorder: true,
        })
        setTimeout(() => {
          this.setState({
            redBorder: false,
          })
        }, 1000)
      } else {
        this.setState({
          step: this.state.step + 1,
        })
      }
    }
  }

  render() {
    return (
      <>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/success"
          ref={this.ContactForm}
          onSubmit={e => {
            if (this.state.step !== 3 || this.state.message === "") {
              e.preventDefault()
            } else {
              e.preventDefault()
              const form = this.ContactForm.current
              fetch("/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: this.encode({
                  "form-name": form.getAttribute("name"),
                  ...this.state,
                }),
              })
                .then(response => {
                  console.log("====================================")
                  console.log(`${JSON.stringify(response, null, 2)}`)
                  console.log("====================================")
                  navigate(form.getAttribute("action"))
                })
                .catch(error => {
                  console.log("====================================")
                  console.log(`error in submiting the form data:${error}`)
                  console.log("====================================")
                })
            }
          }}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.changeInput} />
              <input name="name" value={this.state.name}/>
              <input name="email" value={this.state.email}/>
              <textarea name="message">{this.state.message}</textarea>
            </label>
          </p>
          {this.state.step >= 1 ? (
            <input
              type="text"
              name="name"
              placeholder="Fill with your name"
              value={this.state.name}
              onChange={this.changeInput}
              style={
                this.state.redBorder
                  ? { border: "1px solid red" }
                  : { border: "1px solid #ccc" }
              }
            />
          ) : (
            <></>
          )}
          {this.state.step >= 2 ? (
            <input
              type="email"
              name="email"
              placeholder="Now your email address"
              value={this.state.email}
              onChange={this.changeInput}
              style={
                this.state.redBorder
                  ? { border: "1px solid red" }
                  : { border: "1px solid #ccc" }
              }
            />
          ) : (
            <></>
          )}
          {this.state.step >= 3 ? (
            <textarea
              name="message"
              placeholder="Now write your awesome message :)"
              value={this.state.message}
              onChange={this.changeInput}
            ></textarea>
          ) : (
            <></>
          )}
          {this.state.step === 3 ? (
            <AwesomeButton
              type={this.state.message === "" ? "disabled" : "link"}
              disabled={this.state.message === ""}
            >
              Submit your message
            </AwesomeButton>
          ) : (
            <></>
          )}
        </form>
        {this.state.step !== 3 ? (
          <AwesomeButton type="link" onPress={this.nextStep}>
            Next
          </AwesomeButton>
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default ContactForm
