import React, { Component } from "react"
import SEO from "../components/seo"
import ContactForm from "../components/contact"
import Menu from "../components/menu"
import { AwesomeButton, AwesomeButtonSocial } from "react-awesome-button"

class ContactPage extends Component {
  render() {
    return (
      <>
        <SEO title="Contact" />
        <div className="contact-container">
          <div className="contact">
            <h1>Get in touch</h1>
            <p>
              If you wanna get in touch, talk to me about a project
              collaboration or just say hi, fill up the awesome form below or
              send an email to{" "}
              <a href="mailto:hey@frederon.io">hey@frederon.io</a> and
              let's talk.
            </p>
            <ContactForm></ContactForm>
          </div>
          <div className="socials">
            <h1>Let's get social</h1>
            <p>
              Follow my profiles on Instagram, GitHub and Linkedin or maybe
              play some games with me?
            </p>
            <div className="socials-btn">
              <AwesomeButtonSocial
                type="instagram"
                href="https://instagram.com/frederon8"
                target="_blank"
              >
                Instagram
              </AwesomeButtonSocial>
              <AwesomeButtonSocial
                type="github"
                href="https://github.com/frederon/"
                target="_blank"
              >
                Github
              </AwesomeButtonSocial>
              <AwesomeButtonSocial
                type="linkedin"
                href="https://www.linkedin.com/in/frederic-ronaldi/"
                target="_blank"
              >
                LinkedIn
              </AwesomeButtonSocial>
              <AwesomeButton
                type="github"
                href="https://steamcommunity.com/id/frederon/"
                target="_blank"
              >
                Steam
              </AwesomeButton>
            </div>
          </div>
        </div>
        <Menu active="contact" />
      </>
    )
  }
}

export default ContactPage
